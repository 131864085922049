<template>
  <div class="record">
    <div :class="['record-content', { active: tab == 2 }]">
      <div class="tab" @click="handleTab" />
      <div class="box">
        <div class="null" v-if="list.length == 0">
          <img
            class="null-icon"
            src="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/recordNull.png"
          />
          <div class="null-msg" v-if="tab == 1">暂无领取记录~</div>
          <div class="null-msg" v-else>暂无扣费记录~</div>
        </div>
        <div class="list" v-if="list.length > 0">
          <van-list
            ref="scrollContainer"
            :finished="loadingFinished"
            @load="onLoad"
            loading-text="正在加载..."
            finished-text="没有更多了"
          >
            <div class="list-item" v-for="(item, index) in list" :key="index">
              <div class="list-item-border">
                <!-- 领取记录 -->
                <template v-if="tab == 1">
                  <div class="line">
                    <div class="label">领取权益</div>
                    <div class="value red">
                      {{ item.item_long_name }}
                    </div>
                  </div>
                  <div class="line">
                    <div class="label">领取号码</div>
                    <div class="value">
                      {{ item.phone_number }}
                    </div>
                  </div>
                  <div class="line">
                    <div class="label">领取时间</div>
                    <div class="value">
                      {{ item.exchange_time }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="line">
                    <div class="label">付费号码</div>
                    <div class="value">{{ item.phone_number }}</div>
                  </div>
                  <div class="line">
                    <div class="label">付费金额</div>
                    <div class="value">{{ item.item_price }}</div>
                  </div>
                  <div class="line">
                    <div class="label">付费方式</div>
                    <div class="value">{{ item.pay_platform }}</div>
                  </div>
                  <div class="line">
                    <div class="label">交易时间</div>
                    <div class="value">{{ item.create_time }}</div>
                  </div>
                  <div class="line">
                    <div class="label">交易单号</div>
                    <div class="value">{{ item.pay_id }}</div>
                  </div>
                </template>
              </div>
            </div>
            <!-- 加载更多提示 -->
            <van-loading v-if="!loadingFinished && loading" />
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getRecord } from "@/api/tao.js";

// import { List } from "vant";
export default {
  components: {
    // [List.name]: List,
  },
  data() {
    return {
      tab: 1,
      list: [],
      totalPage: 1, // 总页数
      currentPage: 0, // 当前页
      loading: false, // 是否正在加载状态
      loadingFinished: false, // 是否已加载完所有数据
      reachBottomDistance: 10, // 触发加载更多的距离阈值（可自定义）
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    document.title = "领取记录";
    this.onLoad();
  },
  methods: {
    async onLoad() {
      if (!this.loadingFinished) {
        this.loading = true;
        this.currentPage++;
        // 模拟异步加载新一页数据
        this.fetchData();
      }
    },
    handleTab() {
      this.tab = this.tab == 1 ? 2 : 1;
      this.currentPage = 0;
      this.loadingFinished = false;
      this.list = [];
      this.onLoad();
    },
    async fetchData() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const type = this.tab == 1 ? "exchange" : "pay";
      const { record = [], code = 0 } = await getRecord(this.token, {
        page: this.currentPage,
        data_type: type,
      });
      if (code == 0 && record.length > 0) {
        this.list = [...this.list, ...record];
        if(record.length < 10) {
          this.loadingFinished = true;
        }
      } else {
        // 如果返回空数据，也标记为加载完成
        this.loadingFinished = true;
      }
      this.loading = false;
      this.$toast.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.record {
  background: #ff475d
    url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/recordBG.png")
    center top/contain no-repeat;
  padding: 241px 25px 35px;
  height: 100%;
  box-sizing: border-box;

  &-content {
    height: 100%;
    background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/recordTab1.png")
        center top/contain no-repeat,
      url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/recordBG3.png")
        center bottom/contain no-repeat;
    position: relative;
    background-size: 100%;

    &.active {
      background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/recordTab2.png")
          center top/contain no-repeat,
        url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/recordBG3.png")
          center bottom/contain no-repeat;

      .tab {
        float: left;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 300px;
      left: 0;
      right: 0;
      bottom: 207.4px;
      z-index: 0;
      background: url("https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/recordBG2.png")
        center center/contain repeat;
    }

    .tab {
      float: right;
      width: 286px;
      height: 70px;
    }

    .box {
      clear: both;
      position: absolute;
      // z-index: 1;
      top: 104px;
      bottom: 0;
      left: 0;
      right: 0;

      ::v-deep .van-loading {
        text-align: center;
      }

      .null {
        text-align: center;
        padding-top: 206px;

        &-icon {
          display: block;
          margin: 0 auto;
          width: 139px;
          height: 157px;
        }

        &-msg {
          padding-top: 37px;
          font-size: 28px;
          color: #af3e51;
        }
      }

      .list {
        padding: 0 25px;
        box-sizing: border-box;
        // height: 100%;
          height: 1040px;
          overflow: auto;

        ::v-deep.van-list {
          // height: 1040px;
          // overflow: auto;
        }

        &-item {
          margin-top: 1px;
          padding: 8px;
          background-image: linear-gradient(
              90deg,
              #fd8947 7%,
              #fea063 50%,
              #ffb47c 100%
            ),
            linear-gradient(#ff7148, #ff7148);
          border-radius: 26px;
          margin-bottom: 10px;

          &-border {
            background-image: linear-gradient(
                90deg,
                #fffdfa 7%,
                #fff8ee 50%,
                #fff2e1 100%
              ),
              linear-gradient(#fff5e8, #fff5e8);
            border-radius: 20px;
            font-size: 24px;
            color: #af3e51;
            padding: 30px 32px 30px 22px;

            .line {
              line-height: 48px;
              display: flex;
              justify-content: space-between;

              .label {
                color: rgb(#af3e51, 0.7);
              }

              .red {
                font-size: 28px;
                color: #ff485e;
              }
            }
          }
        }
      }
    }
  }
}
</style>
